import { getPropertyInPath } from './generalFunctions';

const rootMetrics = ['acne', 'wrinkles', 'Skin shine', 'freckles', 'dark_spots', 'melasma', 'uneven_skin_tone', 'pore_dilation', 'redness', 'texture', 'skin_sagging', 'smoothness', 'skin_firmness', 'radiance'];
const metricsPaths = {
  eyebags: [
    { member: 'root', type: 'array', filter: { member: 'description', value: 'eyes' } },
    { member: 'sum_measures', type: 'array', filter: { member: 'description', value: 'eyebags' } },
  ],
  darkcircles: [
    { member: 'root', type: 'array', filter: { member: 'description', value: 'eyes' } },
    { member: 'sum_measures', type: 'array', filter: { member: 'description', value: 'dark circles' } },
  ],
}

const consoleLogger = (message) => console.log(`%${message}`, 'color: orange');

export const getLiveArMetrics = (metricResults, metricList = null, logger = consoleLogger) => {
  const metrics = {};
  const metricsPathKeys = Object.keys(metricsPaths);
  
  if (!metricList) {
    metricList = [...rootMetrics, ...metricsPathKeys];
  }

  metricList.forEach((metricName) => {
    if (rootMetrics.includes(metricName)) {
      const result = metricResults.find(({ description }) => description === metricName);
      if (result) {
        metrics[metricName] = result;
      }
      return;
    }

    if (metricsPathKeys.includes(metricName)) {
      metrics[metricName] = { description: metricName, value: getPropertyInPath(metricResults, metricsPaths[metricName], 'value') };
      return;
    }

    logger(`Visualisation for ${metricName} not implemented`);
  });

  return metrics;
}
