import b64toBlob from 'b64-to-blob';
import { drawPolygon } from './draw';

export function convertFileToBase64(file) {
  return new Promise((resolve) => {
    if (file) {
      let reader = new FileReader();
      reader.addEventListener(
        'load',
        function () {
          resolve(reader.result);
        },
        false,
      );
      reader.readAsDataURL(file);
    }
  });
}

export function base64MimeType(encoded) {
  let result = null;
  if (typeof encoded !== 'string') {
    return result;
  }
  let mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);
  if (mime && mime.length) {
    result = mime[1];
  }
  return result;
}

export function converImageToBitmap(image) {
  let contentType = base64MimeType(image);
  return b64toBlob(image.replace('data:' + contentType + ';base64,', ''), contentType);
}

export function findMasks(results, filterNames = []) {
  let masks = [];
  if (!results) {
    return [];
  }
  const filterMasks = filterNames.length > 0;

  for (let result of results) {
    if (result.measurement_locations) {
      for (let measurement of result.measurement_locations) {
        if (measurement.mask_shapes && (!filterMasks || filterNames.includes(measurement.description))) {
          measurement.mask_shapes.section = result.description;
          measurement.mask_shapes.name = measurement.description;
          masks.push(measurement.mask_shapes);
        }
      }
    }
  }
  return masks;
}

export function getImageDimensions(imageBase64) {
  return new Promise(function (resolve) {
    let imageObject = document.createElement('img');
    imageObject.onload = function () {
      resolve({
        width: imageObject.width,
        height: imageObject.height,
      });
    };
    imageObject.src = imageBase64;
  });
}

const getRatio = ({ srcWidth, srcHeight, maxWidth, maxHeight, orientation }) => {
  const ratioFullHeight = maxHeight / srcHeight;
  const ratioFullWidth = maxWidth / srcWidth;

  switch (orientation) {
    case 'landscape':
      return ratioFullWidth;
    case 'portrait':
      return ratioFullHeight;
    default:
      return Math.max(ratioFullHeight, ratioFullWidth);
  }
};

export function calculateAspectRatioFit(srcWidth, srcHeight, maxWidth, maxHeight, orientation) {
  const ratio = getRatio({ srcWidth, srcHeight, maxWidth, maxHeight, orientation });
  return { width: Math.round(srcWidth * ratio), height: Math.round(srcHeight * ratio), ratio };
}

/*
  Function to get the value of property following a path in a JSON structure.
  Params:
  json - json structure to look in
  path - Array with the path to follor. Each element in array must have this structure:
    { member: name of member to look in. First member is 'root',
      type: 'member' if member is an element in JSON or 'array' if is an array of elements
      filter: filter to apply in array elements:
        {
          member: name of the member we are looking into the array,
          value: value that the member must have to get this element in the array.
        }
    }
  property - value of this property will be returned
*/
export function getPropertyInPath(json, pPath, property) {
  if (!json || !pPath) {
    return null;
  }
  let path = pPath.slice();
  if (path.length === 0) {
    // we are at the path end
    return property ? json[property] : json;
  } else {
    const step = path.shift();
    if (step.type === 'member') {
      return getPropertyInPath(json[step.member], path, property);
    }
    if (step.type === 'array') {
      if (step.member !== 'root' && !Array.isArray(json[step.member])) {
        return null;
      }
      const elementArrayFiltered = (step.member !== 'root' ? json[step.member] : json).find(
        (arrayElement) => arrayElement[step.filter.member] === step.filter.value,
      );
      if (elementArrayFiltered) {
        return getPropertyInPath(elementArrayFiltered, path, property);
      }
    }
    // step.type incorrect
    return null;
  }
}

export function getOrientation(root) {
  let matchMedia = root.msMatchMedia || root.MozMatchMedia || root.WebkitMatchMedia || root.matchMedia;
  let portrait = matchMedia('(orientation: portrait)').matches;
  return portrait ? 'portrait' : 'landscape';
}

export const anonymizeImage = ({ image, dimensions, results }) =>
  new Promise((resolve) => {
    const eyesMeasurementLocations = results.find((result) => result.description === 'eyes').measurement_locations;
    const eyesEllipses = eyesMeasurementLocations.reduce((ellipses, measurement) => {
      ellipses.push(
        measurement.mask_shapes.shapes.find((shape) => shape.color === 0 && shape.type === 'ellipse').params,
      );
      return ellipses;
    }, []);
    let canvas = document.createElement('canvas');
    canvas.setAttribute('id', 'anonymizeCanvas');
    canvas.setAttribute('width', dimensions.width);
    canvas.setAttribute('height', dimensions.height);
    canvas.style.position = 'absolute';
    canvas.style.top = '0px';
    canvas.style.left = '0px';
    canvas.style.transformOrigin = 'top left';
    const context = canvas.getContext('2d');
    let imgObject = document.createElement('img');
    imgObject.src = image;
    imgObject.onload = () => {
      context.drawImage(imgObject, 0, 0);
      eyesEllipses.forEach((ellipse) => {
        const center = {
          x: ellipse[0],
          y: ellipse[1],
        };
        const radius = {
          x: ellipse[2],
          y: ellipse[3],
        };
        const polygon = [
          [center.x - radius.x, center.y - radius.y],
          [center.x + radius.x, center.y - radius.y],
          [center.x + radius.x, center.y + radius.y],
          [center.x - radius.x, center.y + radius.y],
        ];
        drawPolygon(context, polygon, 1, { fill: { color: 'rgb(0,0,0)' } });
      });
      resolve(canvas.toDataURL());
    };
  });

export const asyncForEach = async (array, callback) => {
  for (let index = 0; index < array.length; index++) {
    // eslint-disable-next-line no-await-in-loop
    await callback(array[index], index, array);
  }
};
