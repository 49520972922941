import { getPropertyInPath } from './generalFunctions';
import { findings } from './mapCV';

const liveArMetrics = [
  'acne',
  'darkSpots',
  'eyeColor',
  'darkcircles',
  'dullSkin',
  'eyebags',
  'faceShape',
  'freckles',
  'hyperpigmentation',
  'melasma',
  'poreDilation',
  'radiance',
  'redness',
  'skinAge',
  'skinFirmness',
  'skinFoundation',
  'skinSagging',
  'skinShine',
  'skinUndertone',
  'smoothness',
  'texture',
  'unevenSkinTone',
  'wrinkles',
];

const consoleLogger = (message) => console.log(`%${message}`, 'color: orange');

export const getLiveArMetrics = (metricResults, metricList = null, metricValues, logger = consoleLogger) => {
  const metrics = {};

  const metricsToUse = metricList || liveArMetrics;

  metricsToUse.forEach((metricName) => {
    const path = findings[metricName]?.path;
    if (!path) {
      logger(`Visualisation for ${metricName} not implemented`);
      return;
    }
    const metricData = getPropertyInPath(metricResults, findings[metricName].path);
    if (!metricData) {
      return;
    }
    metrics[metricName] = metricData;
  });

  const metricsWithOverall =
    metricValues?.overall !== undefined
      ? {
          ...metrics,
          overall: {
            description: 'overall',
            value: metricValues.overall,
          },
        }
      : metrics;

  return metricsWithOverall;
};
