export const defaultColors = {
  default: {
    low: [44, 192, 205], // blue
    medium: [253, 164, 98], // orange
    high: [205, 44, 66], // red
    area: [60, 173, 178], // turquoise
    extra1: [255, 255, 255], // white
    extra2: [0, 0, 0], // black
    extra3: [255, 0, 0], // pure red
  }
};

export const getRanges = (colors = defaultColors )=> ({
  wrinkles: [
    { minValue: 0, maxValue: 0.2, color: colors.wrinkles?.low ?? colors.default.low },
    { minValue: 0.2, maxValue: 0.4, color: colors.wrinkles?.medium ?? colors.default.medium },
    { minValue: 0.4, maxValue: 1, color: colors.wrinkles?.high ?? colors.default.high },
  ],

  hyperpigmentation: [
    { minValue: 0, maxValue: 100, color: colors.hyperpigmentation?.low ?? colors.default.low },
    { minValue: 100, maxValue: 200, color: colors.hyperpigmentation?.medium ?? colors.default.medium },
    { minValue: 200, maxValue: 400, color: colors.hyperpigmentation?.high ?? colors.default.high },
  ],

  freckles: [
    { minValue: 0, maxValue: 100, color: colors.freckles?.low ?? colors.default.low },
    { minValue: 100, maxValue: 200, color: colors.freckles?.medium ?? colors.default.medium },
    { minValue: 200, maxValue: 400, color: colors.freckles?.high ?? colors.default.high },
  ],

  darkSpots: [
    { minValue: 0, maxValue: 100, color: colors.darkSpots?.low ?? colors.default.low },
    { minValue: 100, maxValue: 200, color: colors.darkSpots?.medium ?? colors.default.medium },
    { minValue: 200, maxValue: 400, color: colors.darkSpots?.high ?? colors.default.high },
  ],

  acne: [
    { minValue: 0, maxValue: 80, color: colors.acne?.extra2 ?? colors.default.extra2 },
    { minValue: 80, maxValue: 300, color: colors.acne?.low ?? colors.default.low },
    { minValue: 300, maxValue: 1000, color: colors.acne?.medium ?? colors.default.medium },
    { minValue: 1000, maxValue: 2500, color: colors.acne?.high ?? colors.default.high },
  ],

  melasma: [
    { minValue: 0, maxValue: 1.1, color: colors.melasma?.extra1 ?? colors.default.extra1 },
    { minValue: 1.1, maxValue: 2.1, color: colors.melasma?.medium ?? colors.default.medium },
    { minValue: 2.1, maxValue: 10, color: colors.melasma?.low ?? colors.default.low },
  ],

  unevenSkinTone: [
    { minValue: 0, maxValue: 1.1, color: colors.unevenSkinTone?.extra1 ?? colors.default.extra1 },
    { minValue: 1.1, maxValue: 2.1, color: colors.unevenSkinTone?.medium ?? colors.default.medium },
    { minValue: 2.1, maxValue: 10, color: colors.unevenSkinTone?.low ?? colors.default.low },
  ],

  redness: [
    { minValue: 0, maxValue: 1.1, color: colors.redness?.extra1 ?? colors.default.extra1 },
    { minValue: 1.1, maxValue: 2.1, color: colors.redness?.medium ?? colors.default.medium },
    { minValue: 2.1, maxValue: 10, color: colors.redness?.low ?? colors.default.low },
  ],

  texture: [
    { minValue: 0, maxValue: 1.1, color: colors.texture?.extra1 ?? colors.default.extra1 },
    { minValue: 1.1, maxValue: 2.1, color: colors.texture?.medium ?? colors.default.medium },
    { minValue: 2.1, maxValue: 10, color: colors.texture?.low ?? colors.default.low },
  ],
  poreDilation: [{ minValue: 0, maxValue: 1000, color: colors.poreDilation?.extra3 ?? colors.default.extra3 }],
  skinSagging: [
    { minValue: 0, maxValue: 0.33, color: colors.skinSagging?.extra1 ?? colors.default.extra1 },
    { minValue: 0.33, maxValue: 0.66, color: colors.skinSagging?.low ?? colors.default.low },
    { minValue: 0.66, maxValue: 1, color: colors.skinSagging?.high ?? colors.default.high },
  ],
  skinShine: [
    { minValue: 0, maxValue: 2, color: colors.skinShine?.extra1 ?? colors.default.extra1 },
    { minValue: 2, maxValue: 4, color: colors.skinShine?.low ?? colors.default.low },
    { minValue: 4, maxValue: 9, color: colors.skinShine?.extra1 ?? colors.default.extra1 },
  ],
  smoothness: [
    { minValue: 0, maxValue: 0.3, color: colors.smoothness?.low ?? colors.default.low },
    { minValue: 0.3, maxValue: 0.6, color: colors.smoothness?.medium ?? colors.default.medium },
    { minValue: 0.6, maxValue: 1, color: colors.smoothness?.high ?? colors.default.high },
  ],
  eyebags: [
    { minValue: 0, maxValue: 0.3, color: colors.eyebags?.low ?? colors.default.low },
    { minValue: 0.3, maxValue: 0.6, color: colors.eyebags?.medium ?? colors.default.medium },
    { minValue: 0.6, maxValue: 1, color: colors.eyebags?.high ?? colors.default.high },
  ],
  darkcircles: [
    { minValue: 0, maxValue: 0.3, color: colors.darkCircles?.low ?? colors.default.low },
    { minValue: 0.3, maxValue: 0.6, color: colors.darkCircles?.medium ?? colors.default.medium },
    { minValue: 0.6, maxValue: 1, color: colors.darkCircles?.high ?? colors.default.high },
  ],
  skinFirmness: [
    { minValue: 0, maxValue: 0.3, color: colors.skinFirmness?.low ?? colors.default.low },
    { minValue: 0.3, maxValue: 0.6, color: colors.skinFirmness?.medium ?? colors.default.medium },
    { minValue: 0.6, maxValue: 1, color: colors.skinFirmness?.high ?? colors.default.high },
  ],
  radiance: [
    { minValue: 0, maxValue: 0.3, color: colors.radiance?.low ?? colors.default.low },
    { minValue: 0.3, maxValue: 0.6, color: colors.radiance?.medium ?? colors.default.medium },
    { minValue: 0.6, maxValue: 1, color: colors.radiance?.high ?? colors.default.high },
  ],
  dullSkin: [
    { minValue: 0, maxValue: 1, color:  colors.dullSkin?.area ?? colors.default.area },
  ],
  skinAge: [
    { color: colors.extra1 },
  ],
  eyeColor: [
    { color: colors.extra1 },
  ],
  skinFoundation: [
    { color: colors.extra1 },
  ],
  skinUndertone: [
    { color: colors.extra1 },
  ],
  faceShape: [
    { color: colors.extra1 },
  ],
})